.property-name-order {
  color: #2D831F;
  text-decoration: none;
  cursor: pointer;
}
.customModalWidth {
  padding: 0px 30px !important;
}
.customModalWidth .modal-dialog {
  max-width: 1700px;
}
.customModalWidth .modal-dialog iframe{
  height:calc(100vh - 4rem);
}