/*@import 'https://fonts.gstatic.com';
@import 'https://fonts.googleapis.com';*/
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Sarina&display=swap';

body { font-family: 'Roboto', sans-serif !important; }

.sarina { font-family: 'Sarina', cursive; }
.roboto { font-family: 'Roboto', sans-serif; }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title {
  color: #2D831F;
  font-weight: 500;
  /*text-transform: uppercase;*/
}
/*.App .form-control, .App .form-select {
  padding: 10px 15px;
  background-color: #F6F6F6;
  border-color: #D4D4D4;
  height: 40px;
}*/
.App .form-control::placeholder { color: #999; }
.App .btn-primary {
  color: #fff;
  font-size: 14px;
  /*min-width: 150px;*/
  padding: 7px 25px;
  border-color: #2D831F;
  background-color: #2D831F;
}
.App .btn-primary:hover {
    color: #fff;
    background-color: #333;
    border-color: #333;
}
.App .btn-check:focus + .App .btn-primary, .App .btn-primary:focus {
    color: #fff;
    background-color: #333;
    border-color: #333;
    /*box-shadow: 0 0 0 .25rem rgba(51,51,51,.5);*/
}
.App a {
  color: #2D831F;
  text-decoration: none;
}

.dropzone-style {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 5px;
  border-color: #ced4da;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.unit-number-list {
  max-height: 90px;
  overflow: auto;
}
.unit-number-list input.unit-number-box {
  font-size: 12px;
  padding: 0px 5px;
  min-height: 22px;
  margin: 5px 0;
}
.unit-mix-table-block .table td {
  vertical-align: middle;
}
.unit-number-list div:first-child input.unit-number-box {
  margin-top: 0px;
}
.unit-number-list div:last-child input.unit-number-box {
  margin-bottom: 0px;
}

input,
button {
  padding: 7px;
  display: block;
  /*margin: 15px 0 0 0;*/
  border-radius: 4px;
  border: 1px solid #999;
  width: 300px;
}

button {
  width: auto;
}

.err {
  margin: 4px 0 0 0;
  font-size: 13px;
  color: #e64646;
}
.th{
  font-weight: bold;
  color: black;
}/*
.formField{
  margin-left: 450px;
}*/
#email-set-password {
  background: #efefef;
  cursor: not-allowed;
}
.custom-error {
  margin: 4px 0 0 0;
  font-size: 12px;
  color: #e64646;
  line-height: 15px;
  display: block;
}
.custom-contact-table .table-responsive {
  max-height: 405px;
}


.procatlist .material-pricing-table td div, .procatlist .subproduct-data-table td div, .procatlist .subproduct-data-table2 td div {
  padding:0px !important;
}
.procatlist .subproduct-data-table td, .procatlist .subproduct-data-table2 td {
  padding:0px 5px !important;
}
.procatlist .subproduct-data-table td:first-child, .procatlist .subproduct-data-table2 td:first-child {
  padding-left: 20px !important;
}
.procatlist td.structure-title {
  padding-left: 20px !important;
}
.procatlist td.secondaryKit-data {
  padding-left: 40px !important;
  padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.procatlist.orderKits-data-table td.secondaryKit-data {
    padding: 0px !important;
}
.procatlist.orderKits-data-table .subproduct-data-table td:first-child, .procatlist.orderKits-data-table .subproduct-data-table2 td:first-child {
    padding-left: 5px !important;
}
.procatlist.orderKits-data-table * {
  box-sizing: border-box;
}


.addons td {
  vertical-align: middle;
}