.login-wrapper {
	width: 100%;
	display: flex;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
	background-color: #EDEDED;
}
.login-container {
	display: flex;
	width: 100%;
	max-width: 800px;
	min-height: 600px;
	background-color: #FFF;
	box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
}
.login-left {
	width: 45%;
	color: #fff;
	display: flex;
	position: relative;
	flex-direction: column;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(../images/login-bg.jpg);
}
.login-left:before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background: rgba(0,0,0,0.7);
}
.login-left > div {
	position: relative;
	height: 50%;
	display: flex;
	justify-content: center;
}
.login-left > div h2 { margin-bottom: 15px; }
.login-left > div p { 
	font-size: 14px; 
	max-width: 310px; 
	margin:15px auto 0; 
	text-align: center; 
}
.login-left .top { align-items: flex-end; }
.login-left .bottom { align-items: flex-start; }
.login-right {
	padding: 50px 0;
	width: 55%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.login-right form { width: 80%; color: #999; }

.forgot-wrapper {
	width: 100%;
	display: flex;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
	background-color: #EDEDED;
}
.forgot-wrapper form {
	width: 100%;
	margin: 15px;
	background: #FFF; 
	max-width: 500px;
	padding: 50px 90px;
	box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
}